import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"


const SahkoinenIlmoittautuminenKoulutuspaiville = ({
  location: { pathname },
}) => (
  <Layout pathname={pathname}>
    <SEO title="Suomen Hautaustoiminnan keskusliitto Ry - Sähköinen ilmoittautuminen koulutuspäiville" />

    {/* <div className="map-wraper full-container">
      <div className="map-wraper-in">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2249.7250302345374!2d25.729457226714086!3d66.50219984194453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x442b4bf3127a7119%3A0xe63e167ef6df6e79!2sKorkalonkatu%2029%2C%2096200%20Rovaniemi!5e0!3m2!1sen!2sfi!4v1694672936670!5m2!1sen!2sfi"
          width="100%"
          height="300"
          frameborder="0"
          allowFullScreen
          title="google-map"
        />
      </div>
    </div> */}
    <div id="content" className="content full pt-10 pb-10">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-8 col-md-offset-2">

            <h1>Koulutuspäivät Espoon Dipolissa</h1>
            <h2>Hautaamisen asialla - SHK ry 135 vuotta</h2>
            <p className="font-bold">
              Aika: 19.-20.3.2024
              <br />
              Paikka: Dipoli, Otakaari 24, Espoo
            </p>

            <h2>Ohjelma</h2>
            <ul className="list-none">
              <li className="font-bold">Tiistai 19.3.2024</li>
              <li>8.30-9.30 Suolainen aamupala kahvin tai teen kera ja ilmoittautumien</li>
              <li>
                9.30-9.45 Päivien avaus. Puheenjohtaja Mikael Wilén - SHK RY
              </li>
              <li>
                9.45-10.15 Espoon seurakuntayhtymän tervehdys ja esittely <br />
                Yhtymän johtaja Risto Hämäläinen ja hautaustoimen päällikkö Juha Ollila
              </li>
              <li>
                10.15-11.15	Karjalaiset Kuolin Itkut - Äänellä itkeminen ja perinteen elpyminen <br />
                Pirkko Fihlman - Äänellä Itkijät ry
              </li>
              <li>
                11.15-12.15	Muutos hautaamisen alalla <br />
                Väitöskirjatutkija, palvelumuotoilija Marja Kuronen
              </li>
              <li>12.15-13.15 Lounas</li>
              <li>13.15-14.15	Infektioiden ja tarttuvien tautien torjunta vainajatyössä <br />
                Hygieniahoitaja Tutta Marttinen
              </li>
              <li>14.15 Kahvi</li>
              <li>
                14.30-15.30 Siviilihautajaisten kehitysnäkymistä <br />
                Pääsihteeri Esa Ylikoski - Vapaa-ajattelijain liitto ry
              </li>
              <li>16.00 Liittokokous</li>
              <li>
                19.00 Iltaohjelma ja ruokailu <br />
                Radisson Blue Hotel, Otaranta 2, Espoo
              </li>
            </ul>
            <ul className="list-none">
              <li className="font-bold">Keskiviikko 20.3.2024</li>
              <li>
                9.15-10.00 Kuolin doula -palvelu - osa loppuelämän hoitoa <br />
                Anne Rauma - Luonnollisesti Osuuskunta
              </li>
              <li>
                10.00-10.30	Luonnon ja hautausmaiden merkitykset seurakuntalaisille <br />
                Urpu Sarlin, viestintäjohtaja Espoon seurakuntayhtymä
              </li>
              <li>
                10.30-11.00 Kellonummen niittyhautausmaa <br />
                Viia Löppönen, hautaustoimen työnjohtaja, Espoon seurakuntayhtymä
              </li>
              <li>
                11.00-12.00	Ajankohtaista hautausalalta <br />
                Toimitusjohtaja Pekka Kivimaa, SHT-Tukku Oy
              </li>
              <li>12.00-13.00 Lounas</li>
              <li>
                13.00-13.45 Harrin klinikka <br />
                Maankäyttöpäällikkö Harri Palo, kirkkohallitus <br />
                Harri Palon ajankohtaiskatsaus ja vastaukset hänelle etukäteen osoitettuihin kysymyksiin
              </li>
              <li>
                14.45	Päätöskahvit
              </li>

            </ul>
            <h2>Osallistumismaksut ja majoitus</h2>

            <p className="font-bold">
              Espoon 19.-20.2.2024 koulutuspäivien osallistumismaksut:
            </p>
            <div class="flex flex-wrap overflow-hidden">
              <div class="w-1/3 overflow-hidden">Koulutuspaketti ti-ke</div>
              <div class="w-1/3 overflow-hidden">yhdistyksen jäsen </div>
              <div class="w-1/3 overflow-hidden">290 eur</div>
            </div>
            <div class="flex flex-wrap overflow-hidden">
              <div class="w-1/3 overflow-hidden" />
              <div class="w-1/3 overflow-hidden">ei jäsen</div>
              <div class="w-1/3 overflow-hidden">340 eur</div>
            </div>
            <div class="flex flex-wrap overflow-hidden mt-3">
              <div class="w-1/3 overflow-hidden">Yksi päivä</div>
              <div class="w-1/3 overflow-hidden">yhdistyksen jäsen </div>
              <div class="w-1/3 overflow-hidden">180 eur</div>
            </div>
            <div class="flex flex-wrap overflow-hidden">
              <div class="w-1/3 overflow-hidden" />
              <div class="w-1/3 overflow-hidden">ei jäsen</div>
              <div class="w-1/3 overflow-hidden">230 eur</div>
            </div>
            <p>
              Hintoihin sisältyy koulutus, ruokailut sekä keskiviikon
              iltaohjelma. Myös keskiviikkoaamuna saapuville on varattu hotellin
              buffet-aamiainen
            </p>

            <div class="flex flex-wrap overflow-hidden">
              <div class="w-1/3 overflow-hidden">Huonehinnat</div>
              <div class="w-1/3 overflow-hidden">Standard 1 hengen huone</div>
              <div class="w-1/3 overflow-hidden">144 eur/yö</div>
            </div>
            <div class="flex flex-wrap overflow-hidden">
              <div class="w-1/3 overflow-hidden" />
              <div class="w-1/3 overflow-hidden">Standard 2 hengen huone</div>
              <div class="w-1/3 overflow-hidden">164 eur/yö</div>
            </div>
            {/* <div class="flex flex-wrap overflow-hidden">
              <div class="w-1/3 overflow-hidden" />
              <div class="w-1/3 overflow-hidden">
                Superior 1 hengen huone
              </div>
              <div class="w-1/3 overflow-hidden">179 eur/yö</div>
            </div>
            <div class="flex flex-wrap overflow-hidden">
              <div class="w-1/3 overflow-hidden" />
              <div class="w-1/3 overflow-hidden">
                Superior 2 hengen huone
              </div>
              <div class="w-1/3 overflow-hidden">187 eur/yö</div>
            </div> */}
            <br />
            <p>
              {/* <p>
                Majoituskiintiö:  Radisson Blu Espoo Hotel 144 EUR / 1hh standard, 164 EUR / 2hh standard
                </p>
              */}
              <p>
                Huonevaraukset tulee tehdä <b>12.3.2024 klo 18 mennessä</b>. Huomioithan, että erikoishintaisia huoneita on saatavilla rajoitettu määrä.
                Varaukset Radisson Hotels Myyntipalvelu Puhelin: +358 300 870 010 / yksittäiset huonevaraukset Sähköposti: reservations.finland@radissonblu.com
              </p><p>
                Mainitsethan ystävällisesti kiintiötunnuksen <b>ESPOO</b> varausta tehdessä. <a href="https://www.radissonhotels.com/fi-fi/" target="_blank">Radisson Hotels Finland</a>
                Alennuskoodi netissä <b>ESPOO</b>. Varauksen vahvistaminen Varaus on vahvistettava luottokortilla tai maksettava varausvaiheessa.
                Huomioithan ystävällisesti, että luottokorttitietoja ei saa lähettää sähköpostitse. Varauksen viimeinen kuluton peruutuspäivä on 7 vrk ennen saapumispäivää.
              </p>
              <p>
                <b>Laskutusosoitteeksi merkitään seurakunnan verkkolaskuosoite</b>

              </p>


            </p>
          </div>
        </div>
        <div className="row pt-10 pb-10">
          <div className="col-md-5 col-md-offset-1">
            <h1>Sähköinen ilmoittautuminen</h1>

            <div className="spacer-45" />
          </div>
          <div className="col-md-5">
            <div className="contact-form-wrapper ">
              <h3>Ilmoittautumislomake</h3>
              <p className="margin-35">
                Vastaamme mielellämme kysymyksiin ja tiedusteluihin
              </p>
              <form
                name="sahkoinen ilmoittautuminen"
                method="POST"
                action="https://formspree.io/f/mbjnovdp"
                className="contact-form
                clearfix"
              >
                {/* <input
                  type="hidden"
                  name="_next"
                  value="https://shk.fi/kiitos"
                /> */}
                <input
                  type="hidden"
                  name="_subject"
                  value="Ilmoittautuminen koulutuspäiville"
                />

                <label for="nimi">Etunimi (vaaditaan)</label>
                <input
                  name="etuninimi"
                  type="text"
                  id="firstname"
                  className="form-control"
                  placeholder=""
                />
                <label for="nimi">Sukunimi (vaaditaan)</label>
                <input
                  name="sukunimi"
                  type="text"
                  id="lastname"
                  className="form-control"
                  placeholder=""
                />
                <label for="email">Sähköposti (vaaditaan)</label>
                <input
                  name="email"
                  type="text"
                  id="email"
                  className="form-control"
                  placeholder=""
                />
                <label for="email">Ammattinimike</label>
                <input
                  name="ammattinimike"
                  type="text"
                  id="ammattinimike"
                  className="form-control"
                  placeholder=""
                />
                <label for="puhelin">Puhelin</label>
                <input
                  name="puhelin"
                  type="text"
                  id="phone"
                  className="form-control"
                  placeholder=""
                />
                <label for="seurakunta">Seurakunta/yhteisö</label>
                <input
                  name="seurakunta"
                  type="text"
                  id="seurakunta"
                  className="form-control"
                  placeholder=""
                />
                <p>
                  Nimen ja seurakunnan/yhteisön julkaiseminen
                  osallistujaluettelossa: Järjestelyjä varten laaditaan
                  osallistujaluettelo.
                </p>
                <p className="font-bold">
                  Saako nimesi ja seurakuntasi/yhteisösi nimen julkaista
                  osallistujaluettelossa?
                </p>
                <div class="mb-4">
                  <label className="inline-flex items-center">
                    <input
                      name="julkaisulupa"
                      type="radio"
                      id="julkaisulupa"
                      className="form-radio"
                      value="julkaisulupa"
                      checked
                    />
                    <span class="ml-2">Annan julkaisuluvan</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      name="julkaisulupa"
                      type="radio"
                      id="eijulkaisulupaa"
                      value="eijulkaisulupaa"
                      className="form-radio"
                    />
                    <span class="ml-2">En anna julkaisulupaa</span>
                  </label>
                </div>
                <p>Osallistun</p>
                <div class="mb-4">
                  <label className="block items-center">
                    <input
                      name="osallistuu"
                      type="radio"
                      id="kokotapahtuma"
                      className="form-radio"
                      value="kokotapahtuma"
                      checked
                    />
                    <span class="ml-2">Koko koulutustapahtumaan</span>
                  </label>

                  <label className="block items-center">
                    <input
                      name="osallistuu"
                      type="radio"
                      id="vainti"
                      className="form-radio"
                      value="vainti"
                    />
                    <span class="ml-2">Vain ti 19.3.2024</span>
                  </label>
                  <label className="block items-center">
                    <input
                      name="osallistuu"
                      type="radio"
                      id="vainke"
                      className="form-radio"
                      value="vainke"
                    />
                    <span class="ml-2">Vain ke 20.3.2024</span>
                  </label>
                </div>
                <p>Yhdistyksen jäsenyys</p>
                <div class="mb-4">
                  <label className="block items-center">
                    <input
                      name="jasenyys"
                      type="radio"
                      id="shkjasen"
                      className="form-radio"
                      value="shkjasen"
                      checked
                    />
                    <span class="ml-2">Seurakunta/yhteisö SHK:n jäsen</span>
                  </label>

                  <label className="block items-center">
                    <input
                      name="jasenyys"
                      type="radio"
                      id="eishkjasen"
                      className="form-radio"
                      value="eishkjasen"
                    />
                    <span class="ml-2">Ei jäsen</span>
                  </label>
                </div>
                <label for="erityisruovakalio">
                  Mahdollinen erityisruokavalio
                </label>
                <input
                  name="erityisruokavalio"
                  type="text"
                  id="erityisruokavalio"
                  className="form-control"
                  placeholder=""
                />
                <p>Iltaruokailu 19.3.2024</p>
                <div class="mb-4">
                  <label className="block items-center">
                    <input
                      name="iltaruokailu"
                      type="radio"
                      id="osallistuuruokailuun"
                      className="form-radio"
                      value="osallistuuruokailuun"
                      checked
                    />
                    <span class="ml-2">
                      Osallistun iltaruokailuun
                    </span>
                  </label>

                  <label className="block items-center">
                    <input
                      name="iltaruokailu"
                      type="radio"
                      id="enosallisturuokailuun"
                      className="form-radio"
                      value="enosallisturuokailuun"
                    />
                    <span class="ml-2">En osallistu iltaruokailuun</span>
                  </label>
                </div>
                <label for="laskutusosoite">
                  Laskutusosoite (Kipa-osoite, mikäli SRK maksaa laskun)
                  (vaaditaan)
                </label>
                <textarea
                  name="laskutusosoite"
                  type="textarea"
                  id="laskutusosoite"
                  rows="3"
                  className="form-control"
                  placeholder=""
                />
                <label for="henkilo">Henkilö</label>
                <input
                  name="henkilo"
                  type="text"
                  id="henkilo"
                  className="form-control"
                  placeholder=""
                />
                <label for="viesti">Viestisi (vaaditaan)</label>
                <textarea
                  name="viesti"
                  cols="2"
                  rows="2"
                  id="comments"
                  className="form-control"
                  placeholder=""
                />
                <input
                  type="submit"
                  className="button btn-primary"
                  value="Lähetä"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="spacer-60" />
  </Layout >
)

export default SahkoinenIlmoittautuminenKoulutuspaiville
